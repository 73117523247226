import styled from "styled-components";

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 600px;

  img.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  /* @media only screen and (max-width: 767px) {
    height: 670px;
  } */

  @media only screen and (max-width: 767px) {
    height: 800px;
  }
`;

export const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 780px;

  &.text03 {
    max-width: 1100px;
    align-items: flex-end;
  }

  > div.div03 {
    max-width: 445px;
  }

  @media only screen and (max-width: 425px) {
    padding: 0 25px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 72px;
  line-height: 76px;
  text-transform: uppercase;
  margin-bottom: 24px;
  max-width: 500px;
  margin: 0 auto;
  color: #fff;

  &.title02 {
    font-family: "CircularStdBold", sans-serif;
    font-size: 42px;
    line-height: 46px;
    text-transform: none;
    text-align: left;
    max-width: 350px;
    margin: 0 auto 0 0;

    span {
      color: #35c8f4;
    }
  }

  &.title03 {
    font-family: "CircularStdBold", sans-serif;
    font-size: 50px;
    line-height: 50px;
    text-transform: none;
    text-align: left;
    color: #131313;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;

    &.title02 {
      text-align: center;
      margin: 0 auto;
    }

    &.title03 {
      font-size: 30px;
      line-height: 30px;
      color: #ffffff;
      text-align: center;
    }
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;
  max-width: 365px;
  margin: 12px auto 34px auto;
  color: #fff;

  &.description02 {
    font-family: "SourceSansProRegular", sans-serif;
    text-align: left;
    max-width: 310px;
    margin: 12px auto 36px 0;
  }

  &.description03 {
    font-family: "SourceSansProRegular", sans-serif;
    text-align: left;
    color: #131313;
    max-width: 345px;
    margin: 22px auto 32px 0;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;

    &.description02 {
      text-align: center;
      margin: 12px auto 36px auto;
    }

    &.description03 {
      text-align: center;
      color: #ffffff;
    }
  }
`;

export const BoxItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media only screen and (max-width: 767px) {
    width: unset;
    margin: 0 auto;
    align-items: center;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
  width: 100%;
`;

export const Date = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "CircularStdBold", sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-right: 20px;
  background-color: #f1b11e;
  color: #2e3eb9;
  width: 100%;
  max-width: 130px;
  height: 40px;
`;

export const Hour = styled.p`
  display: flex;
  flex-direction: row;
  font-family: "CircularStdBook", sans-serif;
  font-size: 18px;
  line-height: 22px;
`;
