import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  transition: all 200ms ease;

  > div {
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: ${({ open }) => (open ? "#EF9927" : "#EF9927")};
    transition: all 200ms ease;

    ${({ open }) =>
      open &&
      css`
        display: none;

        &:first-child {
          display: block;
          transform: translateY(9px) rotate(-45deg);
          width: 120%;
        }

        &:last-child {
          display: block;
          transform: translateY(-9px) rotate(45deg);
          width: 120%;
        }
      `}
  }
`;
