import { createGlobalStyle } from "styled-components";
import SourceSansProRegular from "~/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf";
import SourceSansProSemiBold from "~/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf";
import SourceSansProBold from "~/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf";
import CircularStdBlack from "~/assets/fonts/Circular_Std/CircularStd-Black.ttf";
import CircularStdBold from "~/assets/fonts/Circular_Std/CircularStd-Bold.ttf";
import CircularStdBook from "~/assets/fonts/Circular_Std/CircularStd-Book.ttf";
import CircularStdMedium from "~/assets/fonts/Circular_Std/CircularStd-Medium.ttf";
import ProximaNovaRegular from "~/assets/fonts/Proxima_Nova/ProximaNova-Regular.ttf";
import ProximaNovaSemiBold from "~/assets/fonts/Proxima_Nova/ProximaNova-Semibold.ttf";
import ProximaNovaBold from "~/assets/fonts/Proxima_Nova/ProximaNova-Bold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "SourceSansProRegular";
    src: url(${SourceSansProRegular}) format("opentype");
  }

  @font-face {
    font-family: "SourceSansProSemiBold";
    src: url(${SourceSansProSemiBold}) format("opentype");
  }

  @font-face {
    font-family: "SourceSansProBold";
    src: url(${SourceSansProBold}) format("opentype");
  }

  @font-face {
    font-family: "CircularStdBlack";
    src: url(${CircularStdBlack}) format("opentype");
  }

  @font-face {
    font-family: "CircularStdBold";
    src: url(${CircularStdBold}) format("opentype");
  }

  @font-face {
    font-family: "CircularStdBook";
    src: url(${CircularStdBook}) format("opentype");
  }

  @font-face {
    font-family: "CircularStdMedium";
    src: url(${CircularStdMedium}) format("opentype");
  }

  @font-face {
    font-family: "ProximaNovaRegular";
    src: url(${ProximaNovaRegular}) format("opentype");
  }

  @font-face {
    font-family: "ProximaNovaSemiBold";
    src: url(${ProximaNovaSemiBold}) format("opentype");
  }

  @font-face {
    font-family: "ProximaNovaBold";
    src: url(${ProximaNovaBold}) format("opentype");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color:#E5E5E5;
    font-family: 'CircularStdBold', sans-serif;
    font-weight: 300;
    overflow-x: hidden;
  }

  a {
    font-family: "CircularStdBold", sans-serif;
    text-decoration: none;
  }

  button {
    font-family: "CircularStdBold", sans-serif;
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input {
    font-family: "FuturaRegular", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'CircularStdBold', sans-serif;
  }

  P {
    font-family: 'SourceSansProRegular', sans-serif;
    font-size: 18px;
    line-height: 22px;
  }

  ul {
    list-style: none;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default GlobalStyle;
