import Bradesco from "~/assets/images/partnerships/bradesco.png";
import Cassi from "~/assets/images/partnerships/cassi.png";
// import GoldenCross from "~/assets/images/partnerships/goldencross.png";
import Inb from "~/assets/images/partnerships/inb.png";
import Mediservice from "~/assets/images/partnerships/mediservice.png";
import Notredame from "~/assets/images/partnerships/notredame.png";
import Petrobras from "~/assets/images/partnerships/petrobras.png";
import Postalsaude from "~/assets/images/partnerships/postalsaude.png";
import Sulamerica from "~/assets/images/partnerships/sulamerica.png";
import Unimed from "~/assets/images/partnerships/unimed.png";
import SaudeLeve from "~/assets/images/partnerships/saude-leve.png";
import Plamesc from "~/assets/images/partnerships/plamesc.png";
import AcelorMittal from "~/assets/images/partnerships/acelormittal.png";

export const dataPartnerships = [
  {
    id: 1,
    image: Bradesco,
    name: `Bradesco`,
  },
  // {
  //   id: 2,
  //   image: GoldenCross,
  //   name: `Golden Cross`,
  // },
  {
    id: 3,
    image: Sulamerica,
    name: `Sul América`,
  },
  {
    id: 4,
    image: Unimed,
    name: `Unimed`,
  },
  {
    id: 5,
    image: Postalsaude,
    name: `Postal Saúde`,
  },
  {
    id: 6,
    image: Mediservice,
    name: `Mediservice`,
  },
  {
    id: 7,
    image: Petrobras,
    name: `Petrobras`,
  },
  {
    id: 8,
    image: Inb,
    name: `INB`,
  },
  {
    id: 9,
    image: Notredame,
    name: `Notre Dame`,
  },
  {
    id: 10,
    image: Cassi,
    name: `Cassi`,
  },
  {
    id: 11,
    image: SaudeLeve,
    name: `Saúde Leve`,
  },
  {
    id: 12,
    image: Plamesc,
    name: `Plamesc`,
  },
  {
    id: 13,
    image: AcelorMittal,
    name: `AcelorMittal`,
  },
];
