import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

function Bars({ open }) {
  return (
    <Container open={open}>
      <div />
      <div />
      <div />
    </Container>
  );
}

export default Bars;

Bars.propTypes = {
  open: PropTypes.bool.isRequired,
};
