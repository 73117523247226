import React from "react";
import { useLocation } from "react-router-dom";

import { Container, Items, Item } from "./styles";

export default function Menu({ clickModal }) {
  const url = useLocation();

  const pathnameUrl = url.pathname;

  const retorno = pathnameUrl.split("/");

  return (
    <Container>
      <Items>
        <li>
          <a href="/">
            <Item efeitoHover={retorno[1] === ""}>Início</Item>
          </a>
        </li>
        <li>
          <a href={retorno[1] === "trabalhe-conosco" ? "/#about" : "#about"}>
            <Item efeitoHover={retorno[1] === "about"}>Clínica</Item>
          </a>
        </li>
        <li>
          <a href={retorno[1] === "trabalhe-conosco" ? "/#team" : "#team"}>
            <Item efeitoHover={retorno[1] === "team"}>Equipe médica</Item>
          </a>
        </li>
        <li>
          <a href={retorno[1] === "trabalhe-conosco" ? "/#agende" : "#agende"}>
            <Item efeitoHover={retorno[1] === "agende"}>
              Contato e consultas
            </Item>
          </a>
        </li>
        {/* <li>
          <a href="/trabalhe-conosco">
            <Item efeitoHover={retorno[1] === "trabalhe-conosco"}>
              Trabalhe Conosco
            </Item>
          </a>
        </li> */}
      </Items>
    </Container>
  );
}
