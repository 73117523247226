import React from "react";
import { BrowserRouter } from "react-router-dom";

import ReactGA from "react-ga";
import GlobalStyle from "./styles/global";

import history from "~/services/history";
import Routes from "./routes";

ReactGA.initialize("UA-181208154-1");

export default function App() {
  return (
    <BrowserRouter forceRefresh basename="/">
      <GlobalStyle />
      <Routes history={history} />
    </BrowserRouter>
  );
}
