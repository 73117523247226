import styled from "styled-components";

export const Container = styled.main`
  display: block;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 475px) {
    .ReactModal__Content.ReactModal__Content--after-open {
      padding-left: 20px !important;
      padding-right: 20px;
    }
  }
`;
