import React from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import Home from "~/pages/Home";
// import TrabalheConosco from "~/pages/TrabalheConosco";

export default function Routes({ history }) {
  ReactGA.pageview(history.location.pathname);
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      {/* <Route path="/trabalhe-conosco" exact component={TrabalheConosco} /> */}
    </Switch>
  );
}
