export const colors = {
  black: "#000",
  yellow: "#fdd51d",
  gray: "#F5F5F5",
  white: "#fff",
  disable: "#8DA1B5",
  danger: "#FF0000",
  carrotOrange: "#EF9927",
  jungleGreen: "#2cb960",
};
