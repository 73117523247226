import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

import { colors } from "~/styles/colors";

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? "0" : "100%")};
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 32px;
  padding-top: 100px;
  background-color: #ffffff;
  z-index: 100;
  transition: all 300ms ease;
`;

export const Menus = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 180px;
  left: ${({ sub }) => (sub ? "-100%" : "0")};
  transition: all 300ms ease;
`;

export const Items = styled.div`
  width: 100%;
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const Item = styled.div`
  font-family: "MuseoSlab700";
  font-size: 18px;
  color: ${({ blue }) => (blue ? "#fdd51d" : colors.white)};
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 22px 0;

  a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: #414141;

    &.custom {
      color: #ffffff;
      margin: 0 auto;
    }
  }

  &:hover {
    background-color: #0002;
  }

  &:before {
    display: ${({ sub }) => (sub ? "block" : "none")};
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent #af9337;
    position: absolute;
    right: 20px;
  }
`;

export const SubTitle = styled.h5`
  font-size: 14px;
  color: #fdd51d;
  margin-bottom: 8px;
  margin-top: ${({ last }) => (last ? "18px" : 0)};
  text-transform: uppercase;
  cursor: default;
`;

export const BackButton = styled.button`
  padding: 0 50px 20px 0;
  margin-bottom: 10px;
`;

export const Scroll = styled(PerfectScrollbar).attrs({
  options: {
    wheelSpeed: 0.3,
  },
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 32px; */

  max-height: calc(100vh - 200px);
  width: 100%;
  overflow: hidden;
  position: relative;

  .ps__rail-y {
    position: absolute;

    width: 5px;
    z-index: 1005;
    border-radius: 15px;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  > a {
    max-width: max-content;
  }

  img {
    width: 100%;
    max-width: max-content;
  }
`;
