import styled from "styled-components";
import { colors } from "./colors";

export const PageContainer = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const PageWrapper = styled.section`
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 48px auto 0 auto;
  .icon-spin {
    align-self: center;
    margin-bottom: 32px;
    color: ${colors.light};
    animation: iconSpin 2s infinite linear;
  }
  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  @media screen and (max-width: 1160px) {
    flex-direction: column;
    padding: 48px 32px;
    margin: 0 auto;
  }
  @media screen and (max-width: 410px) {
    padding: 32px 16px;
  }
`;
