import React from "react";
import { useLocation } from "react-router-dom";

import { Container, Menus, Items, Item, Scroll, Icons } from "./styles";

import { Button } from "~/components/globals";

import Instagram from "~/assets/images/instagram.png";
import Facebook from "~/assets/images/facebook.png";
import Whats from "~/assets/images/whatsapp.svg";

export default function Menu({ open, closeModal }) {
  const url = useLocation();

  const pathnameUrl = url.pathname;

  const retorno = pathnameUrl.split("/");

  return (
    <Container open={open}>
      <Menus>
        <Items>
          <Scroll>
            <Item>
              <a
                href={retorno[1] === "trabalhe-conosco" ? "/#about" : "#about"}
                onClick={closeModal}>
                Clínica
              </a>
            </Item>
            <Item>
              <a
                href={retorno[1] === "trabalhe-conosco" ? "/#team" : "#team"}
                onClick={closeModal}>
                Equipe médica
              </a>
            </Item>
            <Item>
              <a
                href={
                  retorno[1] === "trabalhe-conosco" ? "/#agende" : "#agende"
                }
                onClick={closeModal}>
                Contato e consultas
              </a>
            </Item>
            {/* <Item>
              <a href="/trabalhe-conosco">Trabalhe Conosco</a>
            </Item> */}
            <Item>
              <Button
                action="https://wa.link/totso7"
                target="_blank"
                label="Fale por WhatsApp"
                btType="jungleGreen"
                icon={Whats}
              />
            </Item>
            <Item>
              <Icons>
                <a
                  href="https://www.instagram.com/clinicamolequetravesso/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={Instagram} alt="Instagram" />
                </a>
                <a
                  href="https://m.facebook.com/pages/Clinica-Moleque-Travesso/507426575959086?locale=pt_BR"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </Icons>
            </Item>
          </Scroll>
        </Items>
      </Menus>
    </Container>
  );
}
