import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  padding: 100px 0 84px 0;
  margin: 0 auto;

  img {
    width: 100%;
    max-width: 500px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding: 40px 25px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;

  hr {
    display: flex;
    width: 100%;
    max-width: 60px;
    height: 6px;
    background-color: #ef9927;
    border: none;
    margin-bottom: 14px;
  }

  h1 {
    font-size: 40px;
    line-height: 44px;
    color: #303030;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    color: #9aa7bc;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    align-items: center;

    h1 {
      font-size: 60px;
      line-height: 62px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 425px) {
    h1 {
      font-size: 50px;
      line-height: 52px;
    }
  }

  @media only screen and (max-width: 375px) {
    h1 {
      font-size: 40px;
      line-height: 42px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #646464;
  text-align: justify;

  p {
    width: 46%;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    p {
      font-size: 16px;
      line-height: 18px;
      width: 100%;

      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
`;
