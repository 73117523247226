import React from "react";
import { Container } from "./styles";

export default function Button({ btType, label, action, target, icon, full }) {
  return (
    <Container
      className="custom"
      btType={btType}
      href={action}
      target={target}
      full={full}>
      <img src={icon} alt="icon" />
      {label}
    </Container>
  );
}
