import React from "react";

import { PageContainer } from "~/styles/components";
import { Container, Text, Description } from "./styles";

import AboutImg from "~/assets/images/about.png";

export default function About() {
  return (
    <PageContainer>
      <Container id="about">
        <Text>
          <hr />
          <h1>
            Sobre a<br />
            Moleque Travesso
          </h1>
          <h3>
            Desde dezembro de 1982 com o compromisso de cuidar da saúde e bem
            estar do seu filho
          </h3>
          <Description>
            <p>
              Em 14 de dezembro de 1982, quatro médicos se uniram a fim de
              trazer para Volta Redonda um atendimento pediátrico diferenciado e
              de qualidade. Assim surgiu a Clínica Moleque Travesso.
              <br />
              <br />
              Há quarenta anos, esse compromisso se mantém firme. Nosso trabalho
              é cuidar da saúde e do bem estar das crianças e adolescentes, na
              constante busca por aperfeiçoamento e inovação que proporcionem a
              melhor experiência possível para as famílias.
            </p>
            <p>
              <b>Missão:</b> Prestar atendimento pediátrico e multiprofissional
              de excelência, de forma ética, segura e humanizada à criança e ao
              adolescente.
              <br />
              <br />
              <b>Visão:</b> Ser referência na prevenção, promoção e
              restabelecimento da saúde no atendimento pediátrico, com
              compromisso social e sustentabilidade econômica.
              <br />
              <br />
              <b>Valores:</b> Respeito, ética, confiança, educação, inclusão e
              responsabilidade.
            </p>
          </Description>
        </Text>
        <img src={AboutImg} alt="About" />
      </Container>
    </PageContainer>
  );
}
