import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  height: 160px;
  margin: 0 auto;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

export const Address = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 12px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #6b6b6b;

    > a {
      font-size: 14px;
      line-height: 18px;
      padding: 8px 0;
      width: 180px;
      margin-top: 8px;

      img {
        margin-right: 6px;
      }
    }

    span {
      font-family: "CircularStdBold", sans-serif;
      font-size: 17px;
      line-height: 21px;
      color: #373737;
    }
  }
`;
