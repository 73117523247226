import styled from "styled-components";

export const Container = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Items = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    display: flex;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Item = styled.button.attrs({
  type: "button",
})`
  font-family: "CircularStdBold", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ blue }) => (blue ? "#1B65E5" : "#414141")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 22px 12px;
  background-color: ${({ efeitoHover }) =>
    efeitoHover ? "#f2f2f2" : "transparent"};

  &:hover {
    background-color: #f2f2f2;
    transition: 300ms;
    height: 100%;

    &:after {
      width: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: auto;
    top: -2px;
    width: ${({ efeitoHover }) => (efeitoHover ? "100%" : 0)};
    height: 2px;
    background-color: #1b65e5;
    transition: 300ms;
  }
`;
