/* eslint-disable no-unused-expressions */
import React, { useRef, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";

import Modal from "react-modal";
import { FiLoader } from "react-icons/fi";
import { Input } from "~/components/Form/Input";

import {
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
  ContainerInputFile,
  Arquivo,
} from "./styles";

import Close from "~/assets/images/modalContato/close.png";
import LogoModal from "~/assets/images/modalContato/logo-modal.png";
import IconFeather from "~/assets/images/modalContato/icon-feather-send.svg";

import api from "~/services/api";

function ModalContato({ openModal, closeModal }) {
  const formRef = useRef(null);
  const curriculoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [curriculo, setCurriculo] = useState(undefined);

  function handleChange(event) {
    setCurriculo(event.target.files[0]);
    return curriculoRef.current.click();
  }

  const handleSubmit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const formData = new FormData();

        formData.append("nome", data.nome);
        formData.append("cargo", data.cargo);
        formData.append("curriculo", curriculo || null);

        formRef.current?.setErrors({});

        if (!data.nome || !data.cargo || curriculo === undefined) {
          alert("O formulário deve ser totalmente preenchido!");
          setLoading(false);
          return;
        }

        if (curriculo?.size > 2097152) {
          alert("O tamanho do arquivo excede o limite suportado!");
          setLoading(false);
          return;
        }

        await api.post("cadastro", formData);

        setLoading(false);
        setCurriculo(undefined);
        closeModal();
        alert("Formulário enviado com sucesso!");
      } catch (err) {
        const validationErrors = {};

        setLoading(false);

        alert("O envio falhou, revise seu formulário!");

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
        }
      }
    },
    [curriculo, closeModal]
  );

  return (
    <Modal
      ariaHideApp={false}
      onRequestClose={closeModal}
      isOpen={openModal}
      style={{
        overlay: overlayStyle,
        content: contentStyle,
      }}>
      <ModalButton onClick={closeModal}>
        Voltar
        <img src={Close} alt="close" />
      </ModalButton>
      <ModalContent>
        <img src={LogoModal} alt="Logo" />
        <h1>Trabalhe Conosco</h1>
        <p>Mande seu currículo e venha fazer parte da nossa equipe!</p>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <Input
            type="text"
            label="Seu nome:"
            name="nome"
            placeholder="Digite aqui..."
          />
          <Input
            type="text"
            label="Cargo desejado:"
            name="cargo"
            placeholder="Digite aqui..."
          />
          <ContainerInputFile>
            <label htmlFor="arquivo">Currículo:</label>
            <Arquivo>
              <span>
                {curriculo
                  ? curriculo.name
                  : "Clique aqui para anexar um arquivo"}
              </span>
              <input
                className="arquivo"
                type="file"
                id="arquivo"
                name="arquivo"
                ref={curriculoRef}
                defaultValue=""
                onChange={handleChange}
                accept=".doc,.docx,.pdf"
              />
            </Arquivo>
          </ContainerInputFile>
          <p className="archiveType">
            Formatos .doc, .docx ou .pdf (Máximo 2MB)
          </p>
          {/* <FileInput label="Currículo:" name="curriculo" id="curriculoFile" /> */}
          <button type="submit" disabled={loading}>
            {loading ? (
              <FiLoader size={20} className="icon-spin" />
            ) : (
              <>
                <img src={IconFeather} alt="submit" />
                Enviar
              </>
            )}
          </button>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export default withRouter(ModalContato);
