import styled from "styled-components";

import LeftButton from "~/assets/images/icons/arrow-left.svg";
import RightButton from "~/assets/images/icons/arrow-right.svg";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 72px 0 63px 0;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    rgba(27, 51, 116, 1) 0%,
    rgba(21, 117, 104, 1) 100%
  );

  img {
    width: 100%;
    max-width: 500px;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 25px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 365px;
  margin-bottom: 64px;

  hr {
    display: flex;
    width: 100%;
    max-width: 60px;
    height: 6px;
    background-color: #ef9927;
    border: none;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 40px;
    line-height: 44px;
    color: #ffffff;
    margin-bottom: 18px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  @media only screen and (max-width: 991px) {
    p {
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
  }
`;

export const Slider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
    object-fit: cover;
    border-radius: 50%;
  }

  hr {
    display: flex;
    width: 100%;
    max-width: 160px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.35);
    border: none;
    margin: 18px 0;
  }

  @media only screen and (max-width: 480px) {
    text-align: center;
  }
`;

export const Nome = styled.h2`
  font-family: "CircularStdBold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-top: 21px;
  color: #fff;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 1300px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Skill = styled.p`
  font-family: "CircularStdBook", sans-serif;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-align: center;
  max-width: 180px;
  min-height: 36px;
  color: #ffba1a;
`;

export const CRM = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
`;

export const ContentSlider = styled.div`
  width: 90%;
  padding: 0 70px;
  margin-top: 65px;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    img {
      width: 100%;
      max-width: 170px;
      max-height: 170px;
    }
  }

  .button-prev {
    background-image: url(${LeftButton});
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    position: absolute;
    background-size: 48px 48px;
    left: 0;
    top: 25%;
    cursor: pointer;
    z-index: 1;
  }

  .button-next {
    background-image: url(${RightButton});
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    position: absolute;
    background-size: 48px 48px;
    right: 0;
    top: 25%;
    cursor: pointer;
    z-index: 1;
  }

  .swiper-wrapper .swiper-slide-active {
    padding-left: 80px;
  }

  .swiper-wrapper .swiper-slide-next {
    padding: 0 40px;
  }

  .swiper-wrapper .swiper-slide-duplicate-prev {
    padding-right: 80px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0;

    .swiper-wrapper .swiper-slide-active {
      padding-left: 60px;
    }
  }

  @media only screen and (max-width: 480px) {
    .swiper-wrapper .swiper-slide-active {
      padding-left: 0;
    }
  }
`;
