import React from "react";

import { Container } from "./styles";

import Header from "~/components/Header";
import Banner from "~/components/Banner";
import About from "~/components/About";
import Team from "~/components/Team";
import Schedule from "~/components/Schedule";
import BottomBar from "~/components/BottomBar";
import Footer from "~/components/Footer";

export default function Main() {
  return (
    <Container>
      <Header />
      <Banner />
      <About />
      <Team />
      <Schedule />
      <BottomBar />
      <Footer />
    </Container>
  );
}
