import React from "react";

import "swiper/css/swiper.css";

import { PageContainer } from "~/styles/components";
import {
  Container,
  Content,
  Text,
  Box,
  Information,
  Partnerships,
  BoxPartners,
} from "./styles";

import { Button } from "~/components/globals";

import IconButton from "~/assets/images/icon-button.svg";
import Whats from "~/assets/images/whatsapp.svg";
import Phone from "~/assets/images/phone.png";

import { dataPartnerships } from "~/data/partnerships";

export default function Schedules() {
  const renderDataPartnerships = dataPartnerships.map((partnerships) => (
    <BoxPartners key={partnerships.id}>
      <img src={partnerships.image} alt={partnerships.name} />
    </BoxPartners>
  ));

  return (
    <PageContainer>
      <Container id="agende">
        <Content>
          <Text>
            <hr />
            <h1>Agende sua consulta</h1>
            <p>Clique no link abaixo e faça seu agendamento online!</p>
            {/* <Button
              action="https://clinicamolequetravesso.marcamed.com.br/login"
              target="_blank"
              label="Agendar Consulta"
              btType="carrotOrange"
              icon={IconButton}
            /> */}
            <Button
              action="https://wa.link/totso7"
              target="_blank"
              label="Fale por WhatsApp"
              btType="jungleGreen"
              icon={Whats}
            />
            <p className="phone">Ou agende pelo telefone:</p>
            <Box>
              <img src={Phone} alt="Location" />
              <p>
                Ligue pra gente:
                <div />
                <span>
                  <a href="tel:+55-24-3347-3353">(24) 3347-3353</a>
                  <div />
                  <a href="tel:+55-24-3346-4252">(24) 3346-4252</a>
                </span>
              </p>
            </Box>
          </Text>
          <Information>
            <h3>horários de atendimento:</h3>
            <p className="hour">Segunda à Sexta, das 08h às 20h</p>
            <p className="hour">Sábado, das 08h às 12h</p>
            <hr />
            <h3>convênios atendidos:</h3>
            <p className="partnerships">
              Atendemos Particular e os seguintes convênios: Bradesco Saúde, Sul
              América, Unimed, Postal Saúde, Medservice, Petrobras, Petrobras
              Distribuidora, INB, Notredame Intermedica, Cassi, Saúde Leve,
              Plamesc e Abertta Saúde. Aceitamos cartões de crédito, débito,
              dinheiro e pix.
            </p>
          </Information>
          <Partnerships>{renderDataPartnerships}</Partnerships>
        </Content>
      </Container>
    </PageContainer>
  );
}
