import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  overflow: auto;

  h1 {
    font-family: "CircularStdBold", sans-serif;
    font-size: 34px;
    line-height: 38px;
    color: #101010;
    margin-top: 25px;
    margin-bottom: 13px;
  }

  p {
    font-family: "SourceSansProRegular", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #646464;
    max-width: 337px;
    text-align: center;
  }

  > form {
    width: 100%;
    margin-top: 26px;

    > p {
      &.archiveType {
        font-family: "SourceSansProRegular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #373737;
        margin-top: 14px;
        text-align: left;
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-family: "CircularStdBold", sans-serif;
      font-size: 17px;
      line-height: 17px;
      width: 100%;
      max-width: 186px;
      background: #ef9927;
      color: #ffffff;
      border-radius: 27px;
      padding: 16.5px 0;
      margin: 48px auto 0 auto;

      .icon-spin {
        animation: iconSpin 2s infinite linear;
      }

      @keyframes iconSpin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(359deg);
        }
      }
    }
  }
`;

export const ModalButton = styled.button`
  display: flex;
  gap: 13px;
  font-family: "CircularStdBook", sans-serif;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const overlayStyle = {
  zIndex: 5,
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  backgroundColor: "rgba(0, 0, 0, 0.45)",
  zIndex: 100,
};

export const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  padding: "40px 20px",
  top: 0,
  left: 0,
  minHeight: 150,
  width: "100%",
  height: "100%",
  border: "none",
};

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    span {
      color: #ff0000;
    }
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 42px;

  button {
    font-family: "InterSemiBold", sans-serif;
    font-size: 16px;
    line-height: 20px;

    &:first-child {
      color: #4f4f4f;
      margin-right: 24px;
    }
  }
`;

export const ContainerInputFile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 24px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: "CircularStdBook", sans-serif;
    font-size: 22px;
    line-height: 26px;
    color: #373737;

    @media screen and (max-width: 950px) {
      font-size: 15px;
      line-height: 24px;
    }
  }

  input {
    font-family: "SourceSansProRegular", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #b2b2b2;
    width: 100%;
    padding: 7.5px 0;

    &[type="number"] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[disabled] {
      background-color: #ededed;

      &::placeholder {
        content: "";
      }
    }
  }
`;

export const Arquivo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #707070;

  span {
    display: flex;
    align-items: center;
    font-family: "SourceSansProRegular", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #b2b2b2;
    width: 100%;
    padding: 7.5px 0;
    position: relative;
    background: transparent;
  }

  input.arquivo {
    background-color: #000;
    position: absolute;
    z-index: 9999;
    opacity: 0;
    cursor: pointer;
  }

  input.arquivo::before {
    content: "Escolha aqui";
    display: none;
  }

  button {
    width: 193px;
    background-color: #000;
    height: 40px;
    font-size: 18px;
    line-height: 23px;
  }

  @media only screen and (max-width: 767px) {
    span {
      font-size: 14px;
    }
  }
`;
