import React from "react";

import { Container, Content, Address, Social, Icons } from "./styles";

import Pin from "~/assets/images/pin.png";
import Phone from "~/assets/images/phone.png";
import logo from "~/assets/images/logoTextWhite.png";
import Instagram from "~/assets/images/instagram.png";
import Facebook from "~/assets/images/facebook.png";
import Whatsapp from "~/assets/images/whatsapp-orange.svg";

export default function TopBar() {
  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo" />
        <p className="description">
          Em funcionamento desde 14 de dezembro de 1982, mantém firme o
          compromisso de cuidar da saúde e bem-estar das crianças.
        </p>
        <Address>
          <img src={Pin} alt="Location" />
          <p>
            Av. N. Sra. do Amparo, 81
            <br />
            Niterói, Volta Redonda - RJ
          </p>
        </Address>
        <Address>
          <img src={Phone} alt="Location" />
          <p>
            Ligue pra gente:
            <br />
            <span>(24) 3347-3353</span>
          </p>
        </Address>
        <Social>
          <p>Siga-nos:</p>
          <Icons>
            <a
              href="https://www.instagram.com/clinicamolequetravesso/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a
              href="https://m.facebook.com/pages/Clinica-Moleque-Travesso/507426575959086?locale=pt_BR"
              target="_blank"
              rel="noopener noreferrer">
              <img src={Facebook} alt="Facebook" />
            </a>
            <a
              href="https://wa.link/totso7"
              target="_blank"
              rel="noopener noreferrer">
              <img src={Whatsapp} alt="Whatsapp" />
            </a>
          </Icons>
        </Social>
      </Content>
    </Container>
  );
}
