import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  background-color: #17327d;

  @media only screen and (max-width: 991px) {
    padding: 40px 25px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  height: 160px;
  margin: 0 auto;

  p.description {
    max-width: 265px;
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    height: auto;

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
      text-align: center;

      &.description {
        margin-bottom: 24px;
      }
    }
  }
`;

export const Address = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 12px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;

    span {
      font-family: "CircularStdBold", sans-serif;
      font-size: 17px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  @media only screen and (max-width: 991px) {
    margin-bottom: 24px;

    img {
      margin-bottom: 0;
    }
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80px;

  p {
    margin-bottom: 20px;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a img {
    width: 100%;
    max-width: 24px;
  }
`;
