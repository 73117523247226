import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  background-color: #ef9927;

  @media only screen and (max-width: 991px) {
    padding: 40px 25px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  height: 80px;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Copyright = styled.div`
  display: flex;

  p {
    font-family: "ProximaNovaRegular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #373737;
  }

  @media only screen and (max-width: 991px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const Developed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p.developed {
    font-family: "ProximaNovaRegular", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #373737;
    margin-right: 20px;
  }
`;
