import React from "react";

import { Container, Address } from "./styles";

import { Button } from "~/components/globals";

import IconButton from "~/assets/images/icon-button.svg";
import Pin from "~/assets/images/pin.png";
import Phone from "~/assets/images/phone.png";
import logo from "~/assets/images/logo.png";
import Whats from "~/assets/images/whatsapp.svg";

export default function TopBar() {
  return (
    <Container>
      <img src={logo} alt="Logo" />
      <Address>
        <img src={Pin} alt="Location" />
        <p>
          Av. N. Sra. do Amparo, 81
          <br />
          Niterói, Volta Redonda - RJ
        </p>
      </Address>
      <Address>
        <img src={Pin} alt="Location" />
        <p>
          <b>CRM Clínica:</b> 5296753-0
          <br />
          <b>
            CRM Dr Ricardo Pimenta
            <br />
            (técnico responsável):
          </b>{" "}
          5237779-0
        </p>
      </Address>
      <Address>
        <img src={Phone} alt="Location" />
        <p>
          Ligue pra gente:
          <br />
          <span>(24) 3347-3353</span>
          <br />
          <span>(24) 3346-4252</span>
          <Button
            action="https://wa.link/totso7"
            target="_blank"
            label="Fale por WhatsApp"
            btType="jungleGreen"
            icon={Whats}
          />
        </p>
      </Address>
      {/* <Button
        action="https://clinicamolequetravesso.marcamed.com.br/login"
        target="_blank"
        label="Agendar Consulta"
        btType="carrotOrange"
        icon={IconButton}
      /> */}
    </Container>
  );
}
