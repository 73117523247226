import React, { useState, useEffect, useCallback } from "react";

import useWindowSize from "~/hooks/useWindowSize";

import { MenuDesktop as Menu, MenuMobile, MenuBars } from "../Menu";

import TopBar from "~/components/TopBar";

import { Ghost, Container, Logo, Content, MenuButton } from "./styles";

// import Whats from "~/assets/images/whatsapp.svg";

import ModalContato from "~/components/ModalContato";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openModalContato, setOpenModalContato] = useState(false);
  const window = useWindowSize();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style = null;
    }
  }, [menuOpen]);

  const handleOpenModal = useCallback(() => {
    setOpenModalContato(true);
  }, []);

  return (
    <>
      <TopBar />
      <Ghost />
      <Container>
        <Content>
          <div className="contentMenu">
            {window.width > 1100 ? (
              <Menu clickModal={handleOpenModal} />
            ) : (
              <>
                <a href="/">
                  <Logo />
                </a>
                <MenuMobile
                  open={menuOpen}
                  closeModal={() => setMenuOpen(!menuOpen)}
                />
              </>
            )}
          </div>
          {window.width > 1100 ? (
            <span />
          ) : (
            <>
              <MenuButton type="button" onClick={() => setMenuOpen(!menuOpen)}>
                <MenuBars open={menuOpen} size={50} />
              </MenuButton>
            </>
          )}
        </Content>
      </Container>
      <ModalContato
        openModal={openModalContato}
        closeModal={() => setOpenModalContato(false)}
      />
    </>
  );
}
