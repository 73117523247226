import React from "react";

import { Container, Content, Copyright, Developed } from "./styles";

import LogoProxima from '~/assets/images/logo-proxima.png';

export default function Footer() {
  return (
    <Container>
      <Content>
        <Copyright>
          <p>Clínica Moleque Travesso - Todos os direitos reservados.</p>
        </Copyright>
        <Developed>
          <p className="developed">Desenvolvido por:</p>
          <img src={LogoProxima} alt="Logo Proxima" />
        </Developed>
      </Content>
    </Container>
  );
}
