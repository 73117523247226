import React from "react";

import useWindowSize from "~/hooks/useWindowSize";

import {
  Banner,
  Text,
  Title,
  Description,
  BoxItems,
  Items,
  Item,
  Date,
  Hour,
} from "./styles";

import imageBanner from "~/assets/images/banner/banner.png";
import imageBannerChild from "~/assets/images/banner/banner-child.png";
import imageBannerChildMobile from "~/assets/images/banner/banner-child-mobile.png";
// import imageButton from "../../assets/images/banner/button.svg";

import { Button } from "~/components/globals";

import IconButton from "~/assets/images/icon-button.svg";

export default function ComponentBanner() {
  const window = useWindowSize();

  return (
    <Banner>
      {/* <img src={imageBanner} alt="Banner" className="background" /> */}
      {window.width > 767 ? (
        <img src={imageBannerChild} alt="Banner" className="background" />
      ) : (
        <img src={imageBannerChildMobile} alt="Banner" className="background" />
      )}
      <Text className="text03">
        {/* <Title>Excelência em Pediatria</Title>
        <Description>
          Traga os seus filhos para uma clínica especializada e com
          profissionais qualificados.{" "}
          <strong>Atendemos pacientes de 0 a 18 anos.</strong>
        </Description> */}
        {/* <Title className="title02">
          Horários de <span>Funcionamento:</span>
        </Title>
        <Description className="description02">
          Confira como será o funcionamento da clínica durante os próximos dias:
        </Description>
        <BoxItems>
          <Items>
            <Item>
              <Date>26/03 (Sex)</Date>
              <Hour>Horário normal, de 08h às 20h</Hour>
            </Item>
            <Item>
              <Date>27/03 (Sáb)</Date>
              <Hour>De 08h às 12h</Hour>
            </Item>
            <Item>
              <Date>29/03 (Seg)</Date>
              <Hour>Horário normal, de 08h às 20h</Hour>
            </Item>
            <Item>
              <Date>30/03 (Ter)</Date>
              <Hour>Horário normal, de 08h às 20h</Hour>
            </Item>
          </Items>
          <Items>
            <Item>
              <Date>31/03 (Qua)</Date>
              <Hour>Horário normal, de 08h às 20h</Hour>
            </Item>
            <Item>
              <Date>01/04 (Qui)</Date>
              <Hour>Horário normal, de 08h às 20h</Hour>
            </Item>
            <Item>
              <Date>02/04 (Sex)</Date>
              <Hour>Feriado (Fechado)</Hour>
            </Item>
          </Items>
        </BoxItems> */}
        <div className="div03">
          <Title className="title03">Seu filho merece cuidado e atenção!</Title>
          <Description className="description03">
            Traga seu filho para uma clínica especializada e com profissionais
            qualificados.
            <br />
            Atendemos pacientes de 0 a 18 anos.
          </Description>
        </div>
        {/* <Button
          action="https://clinicamolequetravesso.marcamed.com.br/login"
          target="_blank"
          label="Agendar Consulta"
          btType="carrotOrange"
          icon={IconButton}
        /> */}
      </Text>
    </Banner>
  );
}
