import styled from "styled-components";
import { colors } from "~/styles/colors";

export const Container = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 21px;
  width: 100%;
  max-width: 225px;
  padding: 15px 0;
  background-color: ${({ btType }) =>
    btType ? colors[btType] : colors.primary};
  color: ${({ btType }) =>
    btType === "disable" ? colors.light : colors.white};
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.98);
  }

  svg {
    margin-right: 8px;
    color: ${({ btType }) =>
      btType === "disable" ? colors.light : colors.white};
  }

  img {
    margin-right: 9px;
  }
`;
