import axios from "axios";

const headers = {
  Accept: "application/json",
};

const api = axios.create({
  baseURL: "https://molequetravesso-api-d09623b76d00.herokuapp.com/",
  headers,
});

export default api;
