import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background-color: #ebebeb;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  padding: 65px 0;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding: 40px 25px;
    text-align: center;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 445px;

  hr {
    display: flex;
    width: 100%;
    max-width: 60px;
    height: 6px;
    background-color: #ef9927;
    border: none;
    margin-bottom: 12px;
  }

  h1 {
    font-size: 40px;
    line-height: 44px;
    color: #303030;
    margin-bottom: 18px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    color: #646464;
    margin: 22px 0 38px 0;

    &.phone {
      margin: 38px 0 21px 0;
    }
  }

  a:nth-child(2n) {
    margin-bottom: 24px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 265px;
  background-color: #ffffff;
  padding: 22px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px;
  -moz-box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px;

  img {
    margin-right: 12px;
  }

  p {
    font-family: "SourceSansProSemiBold", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #6b6b6b;
    margin: 0;

    span {
      font-family: "CircularStdBold", sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: #373737;

      > a {
        text-decoration: none;
        color: #373737;

        &.margin {
          margin-bottom: 8px;
        }
      }
    }

    div {
      width: 100%;
      height: 4px;
    }
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0 50px 0;

  h3 {
    font-family: "CircularStdBold", sans-serif;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #1b65e5;
  }

  p {
    font-family: "ProximaNovaRegular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #646464;

    &.hour {
      font-family: "ProximaNovaBold", sans-serif;
      color: #373737;
      margin-top: 21px;
      text-align: center;
    }

    &.partnerships {
      max-width: 635px;
      text-align: center;
      margin-top: 21px;
    }
  }

  hr {
    display: flex;
    width: 100%;
    max-width: 565px;
    height: 1px;
    background-color: #8dc6d2;
    border: none;
    margin: 24px 0;
  }
`;

export const Partnerships = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  li {
    margin-left: 2.6%;
    margin-bottom: 2.6%;
  }

  @media only screen and (max-width: 370px) {
    justify-content: center;
  }
`;

export const BoxPartners = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 17%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 5px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    max-width: 160px;
  }
`;
