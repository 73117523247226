import React, { useState } from "react";
import Swiper from "react-id-swiper";

import "swiper/css/swiper.css";

import { PageContainer } from "~/styles/components";
import {
  Container,
  Text,
  Slider,
  Nome,
  Skill,
  CRM,
  ContentSlider,
} from "./styles";

import { dataTeam } from "~/data/team";

export default function Team() {
  const [swiper, updateSwiper] = useState(null);

  const params = {
    swiper,
    initialSlide: 0,
    loop: true,
    autoplay: true,
    loopFillGroupWithBlank: true,
    getSwiper: updateSwiper,
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    //   clickable: true,
    // },
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
      el: ".swiper-pagination",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 2,
      },
      675: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 2,
      },
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 2,
      },
      1400: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
      },
    },
  };

  const renderDataTeam = dataTeam.map((team) => (
    <Slider key={team.id}>
      <img src={team.image} alt={team.name} />
      <Nome>{team.name}</Nome>
      <hr />
      <Skill>{team.skill}</Skill>
      <CRM>CRM: {team.crm}</CRM>
    </Slider>
  ));

  return (
    <PageContainer>
      <Container id="team">
        <Text>
          <hr />
          <h1>Equipe Médica</h1>
          <p>Profissionais de referência na região.</p>
        </Text>
        <ContentSlider>
          <Swiper {...params}>{renderDataTeam}</Swiper>
        </ContentSlider>
      </Container>
    </PageContainer>
  );
}
