import CristianePacheco from "~/assets/images/team/cristiane-pacheco.png";
import KatiaRosas from "~/assets/images/team/katia-rosas.png";
import LucianaGervasio from "~/assets/images/team/luciana-gervasio.png";
import MarianaBaptista from "~/assets/images/team/mariana-baptista.png";
import MariaTepedino from "~/assets/images/team/marina-tepedino.png";
import MiguelTepedino from "~/assets/images/team/miguel-tepedino.png";
import RicardoBarbosa from "~/assets/images/team/ricardo-barbosa.png";
import RicardoPimenta from "~/assets/images/team/ricardo-pimenta.png";
import SueliMaria from "~/assets/images/team/sueli-maria.png";

export const dataTeam = [
  {
    id: 1,
    image: CristianePacheco,
    name: `Dra. Cristiane Pacheco Magalhães`,
    skill: `pediatria e especialista em síndrome de down`,
    crm: `52.74872-2`,
  },
  {
    id: 2,
    image: KatiaRosas,
    name: `Dra. Kátia Rosas Cruz`,
    skill: `pediatria`,
    crm: `52.41734-0`,
  },
  {
    id: 3,
    image: LucianaGervasio,
    name: `Dra. Luciana Galante`,
    skill: `pediatria, neonatologia e homeopatia`,
    crm: `52.58471-0`,
  },
  {
    id: 4,
    image: MarianaBaptista,
    name: `Dra. Mariana Baptista Freitas`,
    skill: `Cirurgia Pediátrica`,
    crm: `52.85963-0`,
  },
  {
    id: 5,
    image: MiguelTepedino,
    name: `Dr. Miguel Tepedino Neto`,
    skill: `pediatria e homeopatia`,
    crm: `52.34209-8`,
  },
  {
    id: 6,
    image: RicardoBarbosa,
    name: `Dr. Ricardo Barbosa Pinheiro`,
    skill: `pediatria`,
    crm: `52.34896-5`,
  },
  {
    id: 7,
    image: RicardoPimenta,
    name: `Dr. Ricardo Pimenta da Cruz`,
    skill: `pediatria`,
    crm: `52.37779-0`,
  },
  {
    id: 8,
    image: SueliMaria,
    name: `Dra. Sueli Maria Ferreira`,
    skill: `pediatria`,
    crm: `52.37442-5`,
  },
  {
    id: 9,
    image: MariaTepedino,
    name: `Dra. Marina Tepedino`,
    skill: `Alergista e Imunologista Pediátrica`,
    crm: `52.108064-4`,
  },
];
