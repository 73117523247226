// @ts-nocheck
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1.5rem;
  margin-bottom: 42px;
  position: relative;
  border-bottom: 1px solid #707070;

  span {
    font-size: 14px;
    font-weight: 700;
    color: #c53030;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  font-family: "CircularStdBook", sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: ${({ disabled }) => (disabled ? "#E5E5E5" : "#373737")};

  @media screen and (max-width: 950px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7.5px 0;
  position: relative;
  background: transparent;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--principal-40);
      border-color: var(--principal-40);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--principal-40);
    `}

  input {
    flex: 1;
    background: transparent;
    font-family: "SourceSansProRegular", sans-serif;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    border: none;
    outline: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset;
      -webkit-text-fill-color: var(--cinza-90);
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #b2b2b2;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b2b2b2;
    }

    &::placeholder {
      color: #b2b2b2;
    }
  }

  input.MuiInputBase-input {
    padding: 1px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 950px) {
    input,
    input::placeholder {
      font-size: 17px;
      line-height: 28px;
    }

    span,
    span.resetPassword {
      font-size: 17px;
      line-height: 28px;
    }
  }
`;
