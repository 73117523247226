import styled from "styled-components";

import logo from "~/assets/images/logo.png";
import { colors } from "~/styles/colors";

export const Ghost = styled.div`
  width: 100%;
  display: none;

  @media screen and (max-width: 950px) {
    height: 87px;
    display: block;
  }

  @media screen and (max-width: 400px) {
    height: 72px;
    display: block;
  }
`;

export const Container = styled.header`
  width: 100%;
  background-color: #ffffff;
  border-top: 2px #dedede solid;

  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    z-index: 100;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 665px;
  padding: 0;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  .contentMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 675px;
  }

  @media screen and (max-width: 1100px) {
    max-width: 950px;
    padding: 32px 25px;

    .contentMenu a {
      z-index: 150;
    }
  }

  @media screen and (max-width: 950px) {
    padding: 16px 32px;
    justify-content: flex-start;
  }
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  object-fit: cover;
  z-index: 101;

  @media screen and (max-width: 1100px) {
    height: 50px;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  font-family: "MuseoSlab700", serif;
  font-size: 18px;
  z-index: 101;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    color: ${colors.primary};
  }
`;
